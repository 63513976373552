.LiCSSsLiveEditor {
  border: 1px #000 solid;
  font-size: 16px;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100vw;
}

.LiCSSsLiveEditor,
.LiCSSsLiveEditor * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.LiCSSsLiveEditor__toolbar, .LiCSSsLiveEditor__editor-input, .LiCSSsLiveEditor__editor-preview {
  padding: 10px 15px;
  max-width: 100%;
  overflow: visible;
  word-wrap: break-word;
}

.LiCSSsLiveEditor__toolbar {
  border-bottom: 1px #000 solid;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 1fr auto;
      grid-template-columns: auto 1fr auto;
}

.LiCSSsLiveEditor__toolbar-inline {
  padding: 0 10px;
}

.LiCSSsLiveEditor__toolbar-inline--modal {
  background-color: #fff;
  padding: 5px;
  position: absolute;
}

.LiCSSsLiveEditor__editor {
  max-width: 100vw;
}

.LiCSSsLiveEditor__editor-input {
  border-right: 1px #000 solid;
}

.LiCSSsLiveEditor__editor-block {
  border: 0;
  margin: 0;
  height: 1.25em;
  outline: none;
  padding: 0;
  resize: none;
  overflow: hidden;
  width: 100%;
}

.LiCSSsLiveEditor__footer {
  border-top: 1px #000 solid;
  font-size: .75em;
  padding: 5px;
  text-align: right;
}

.LiCSSsLiveEditor__footer p {
  margin: 0;
  padding: 0;
}

.LiCSSsLiveEditor__blockElements {
  max-width: 100%;
  overflow: visible;
  word-wrap: break-word;
}

.LiCSSsLiveEditor__blockElements,
.LiCSSsLiveEditor__blockElements * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.LiCSSsLiveEditor__blockElements p {
  margin: 3px 0;
  min-height: 1em;
}
